import { login, logout, query, getDomain, getBranding } from "sinao-corejs";

const actions = {
	async branding({ commit }) {
		const brand = await getBranding();
		const { icon, logo, name } = brand;

		commit("SET_LOGO", logo);
		commit("SET_NAME", name);

		const favicon = document.querySelector("link[rel='icon']");
		favicon.href = icon;

		return brand;
	},
	sendPassword(_, payload) {
		const { email } = payload;

		return query({
			operationId: "auth.sendpassword",
			data: {
				email
			}
		});
	},
	changePassword(_, payload) {
		const { password, forgotten_password_token } = payload;

		return query({
			operationId: "auth.changepassword",
			data: {
				password,
				forgotten_password_token
			}
		});
	},
	deleteInviteByToken(_, accessToken) {
		return query({
			operationId: "app.policies.registration.delete",
			params: {
				accessToken
			}
		});
	},
	createInvite(_, payload) {
		const { token, firstname, lastname, password, cgu } = payload;

		return query({
			operationId: "app.policies.registration.register",
			params: {
				accessToken: token
			},
			data: {
				firstname,
				lastname,
				password,
				cgu
			}
		});
	},
	async getInvite(_, accessToken) {
		const response = await query({
			operationId: "app.policies.registration.get",
			params: {
				accessToken,
				"expand[0]": "app",
				"expand[1]": "app.organization",
				"expand[2]": "recipient_user",
				"expand[3]": "sender_user"
			}
		});

		return response;
	},
	async getUser({ commit }) {
		const response = await query(
			{
				operationId: "account.get",
				params: {
					"expand[0]": "accounting_firm"
				}
			},
			true
		);

		commit("SET_USER", response);
	},
	async login(_, payload) {
		const { email, password, code } = payload;

		const response = await query(
			{
				operationId: "auth.login",
				data: {
					email: email,
					password: password,
					code: code ?? null
				}
			},
			true
		);

		if (response.access_token) {
			return await login({
				token: response.access_token
			});
		}

		return response;
	},
	async logout({ commit }, token) {
		query(
			{
				operationId: "auth.logout",
				params: {
					token
				}
			},
			true
		);

		commit("SET_USER", null);

		logout();
	},
	async getApps() {
		const { data } = await query({
			operationId: "app.list",
			params: {
				"expand[0]": "organization",
				"expand[1]": "policies",
				"expand[2]": "policies.user",
				"expand[3]": "policies.profile",
				"expand[4]": "subscription",
				"expand[5]": "invites",
				"expand[6]": "organization.headquarters",
				"expand[7]": "admin",
				limit: "1000"
			}
		});

		return data;
	},
	async getAppByAppId(_, appId) {
		const response = await query({
			operationId: "app.get",
			params: {
				appId,
				"expand[0]": "organization",
				"expand[1]": "policies",
				"expand[2]": "policies.user",
				"expand[3]": "policies.profile",
				"expand[4]": "invites",
				"expand[5]": "admin",
				"expand[6]": "subscription"
			}
		});

		return response;
	},
	async createApp(_, params) {
		await query({
			operationId: "app.create",
			params: params /* organization_name + organization_size */
		});
	},
	async updateAppSettings(_, { appId, token, data }) {
		return query({
			operationId: "app.settings.update",
			token,
			params: {
				appId
			},
			data: {
				settings: {
					data
				}
			}
		});
	},
	async getPolicies(_, appId) {
		const { data } = await query({
			operationId: "app.policies.profiles.list",
			params: { appId }
		});

		return data;
	},
	async updatePolicy(_, payload) {
		const { appId, userId, policyProfileId } = payload;

		return await query({
			operationId: "app.policies.update",
			params: {
				appId,
				userId,
				policy_profile_id: policyProfileId
			}
		});
	},
	async deleteAccess(_, payload) {
		const { appId, userId } = payload;

		await query({
			operationId: "app.policies.delete",
			params: {
				appId,
				userId
			}
		});
	},
	async sendInviteMail(_, payload) {
		const { appId, email, policyProfileId, firstname, lastname, password } = payload;
		const _params = {
			appId,
			email,
			firstname,
			lastname,
			password,
			policy_profile_id: policyProfileId
		};
		if (payload.additionalsAppId) {
			_params.additionalsAppId = payload.additionalsAppId;
		}

		await query({
			operationId: "app.policies.invitations.create",
			params: { appId },
			data: _params
		});
	},
	async deleteInviteById(_, payload) {
		const { appId, id } = payload;

		return await query({
			operationId: "app.policies.invitations.delete",
			params: {
				appId,
				id
			}
		});
	},
	async saveAccountingFirmUserPolicies(_, payload) {
		const { userId, appId } = payload;
		await query({
			operationId: "app.policies.accounting_batch",
			params: {
				userId,
				appId
			}
		});
	},
	async sendCodeLogin(_, payload) {
		const { email, password } = payload;

		return await query({
			operationId: "auth.send_2fa_code",
			data: {
				email,
				password
			}
		});
	}
};

export default actions;
