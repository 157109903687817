var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-card',{staticClass:"shadow-none my-3"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell"},[_c('img',{staticClass:"rounded-full shadow-md m-auto mr-2",staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.getImageUrl(_vm.accountant),"height":"35px","width":"35px"}})]),_c('div',{staticClass:"table-cell md:text-left md:ml-3",staticStyle:{"vertical-align":"middle"}},[_c('h5',{staticClass:"title-sinao font-bold pb-1"},[_vm._v(_vm._s(_vm.accountant.name))])])])]),_c('div',{staticClass:"w-full flex-1 flex justify-end"},[_c('div',{staticClass:"flex pl-4"},_vm._l((_vm.getAccountantApps()),function(app){return _c('vs-tooltip',{key:'acc_' + _vm.accountant.id + 'app_' + app.id,attrs:{"color":app.bgcolor,"text":app.organization.name}},[_c('div',{staticClass:"rounded-full shadow-md text-white uppercase text-lg font-medium",style:({
							'background-color': app.bgcolor,
							width: '35px',
							height: '35px',
							'margin-left': '-8px',
							'line-height': '35px'
						})},[_vm._v(" "+_vm._s(app.initials)+" ")])])}),1)]),_c('accountant-apps-selector',{attrs:{"accountant":_vm.accountant,"apps":_vm.apps}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }