<template>
	<div id="page-login" class="flex w-full layout--middle">
		<div class="card-login sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
			<vx-card v-if="!showTwoFactorForm" class="shadow-none">
				<div class="h-full sm:w-full lg:w-2/3 mx-auto self-center bg-white bg-dark pt-10 pb-10">
					<div class="vx-card__title mb-8">
						<h4 class="mb-4">{{ $t("Connexion") }}</h4>
						<p>{{ $t("Connectez-vous à votre compte") }} {{ name }}</p>
						<vs-alert
							type="warning"
							:active="showLoginError"
							color="danger"
							style="height: fit-content !important"
						>
							{{ codeError ? $t(codeError) : $t("Les données de connexion ne sont pas correctes.") }}
						</vs-alert>
					</div>
					<!-- email -->
					<div class="center grid">
						<vs-row>
							<vs-col vs-type="flex" w="12" class="p-0">
								<vs-input
									v-model="email"
									v-validate="'required|email'"
									data-vv-validate-on="blur"
									name="email"
									icon="icon icon-mail"
									icon-pack="feather"
									:label-placeholder="$t('Email')"
									class="w-full no-icon-border"
									color="var(--black)"
									@keypress.enter="login"
								/>
							</vs-col>
							<vs-col vs-type="flex" vs-justify="start" vs-align="center" style="width: 100%">
								<span v-show="errors.has('email')" class="text-danger text-sm">{{
									errors.first("email")
								}}</span>
							</vs-col>
						</vs-row>
						<!-- mot de passe -->
						<vs-row>
							<vs-col vs-type="flex" vs-justify="start" vs-align="center" class="p-0" style="width: 100%">
								<div class="relative w-full">
									<vs-input
										ref="password"
										id="password"
										v-model="password"
										:style="{ display: password_type === 'password' ? 'block' : 'none' }"
										v-validate="'required'"
										icon-pack="feather"
										icon="icon-lock"
										icon-no-border
										type="password"
										data-vv-validate-on="blur"
										name="password"
										:label-placeholder="$t('Mot de passe')"
										class="w-full mt-6 no-icon-border"
										color="var(--black)"
										@keypress.enter="login"
										autocomplete="current-password"
									/>

									<vs-input
										ref="password_text"
										id="password_text"
										v-model="password"
										:style="{ display: password_type === 'text' ? 'block' : 'none' }"
										v-validate="'required'"
										icon-pack="feather"
										icon="icon-lock"
										icon-no-border
										type="text"
										data-vv-validate-on="blur"
										name="password_text"
										:label-placeholder="$t('Mot de passe')"
										class="w-full mt-6 no-icon-border"
										color="var(--black)"
										@keypress.enter="login"
									/>

									<vs-icon
										:icon="password_type == 'password' ? 'icon-eye' : 'icon-eye-off'"
										icon-pack="feather"
										size="1.1rem"
										class="mt-4 icon_eye login_eye_icon cursor-pointer"
										@click="togglePassword"
									/>
								</div>
							</vs-col>
							<vs-col vs-type="flex" vs-justify="start" vs-align="center" style="width: 100%">
								<span v-show="errors.has('password')" class="text-danger text-sm pt-2">{{
									errors.first("password")
								}}</span>
							</vs-col>
						</vs-row>
					</div>
					<!-- mot de passe oublié -->
					<div class="flex flex-wrap justify-between my-5">
						<router-link :to="{ name: 'Sendpassword' }"> {{ $t("Mot de passe oublié ?") }} </router-link>
					</div>

					<!-- soumission du formulaire -->
					<s-button
						ref="submit"
						class="w-full mb-3 vs-con-loading__container"
						:disabled="disabled"
						@click="login"
					>
						{{ $t("Connexion") }}
					</s-button>

					<s-button class="w-full" type="border" @click="createAccount">
						{{ $t("Créer un compte") }}
					</s-button>
				</div>
			</vx-card>

			<vx-card v-else class="shadow-none">
				<div class="h-full sm:w-full lg:w-2/3 mx-auto self-center bg-white bg-dark pt-10 pb-10">
					<div class="vx-card__title mb-8">
						<h4 class="mb-4">{{ $t("Connexion") }}</h4>
						<p>
							{{
								$t(
									"Un code de validation vous a été envoyé par mail, valable pendant les 10 prochaines minutes."
								)
							}}
						</p>
						<vs-alert type="warning" :active="showLoginError" color="danger">
							{{ codeError ? $t(codeError) : $t("Les données de connexion ne sont pas correctes.") }}
						</vs-alert>
					</div>

					<div class="center grid">
						<span> {{ $t("Entrer le code de validation :") }} </span>
						<vs-row>
							<vs-col vs-type="flex" w="12" class="p-0">
								<div v-for="i in 6" :key="'pin-' + i" class="pin-input-group">
									<vs-input
										v-model="code[i - 1]"
										v-validate="'required|min:1|max:1'"
										:name="'pin-' + i"
										class="pin-input"
										maxlength="1"
										color="var(--black)"
										autocomplete="off"
										@input="handleInput(i - 1, $event)"
										@paste="handlePaste"
										@keydown="handleKeyNavigation(i - 1, $event)"
									/>

									<svg
										v-if="i == 3"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
										class="lucide lucide-dot-icon"
									>
										<circle cx="12.1" cy="12.1" r="1"></circle>
									</svg>
								</div>
							</vs-col>
							<vs-col vs-type="flex" vs-justify="start" vs-align="center" style="width: 100%">
								<span v-show="errors.has('email')" class="text-danger text-sm">{{
									errors.first("email")
								}}</span>
							</vs-col>
						</vs-row>
					</div>

					<!-- mot de passe oublié -->
					<div class="flex flex-wrap justify-between my-5">
						<a class="link" @click="resend_code">{{ $t("Je n'ai pas reçu le code") }}</a>
					</div>

					<!-- soumission du formulaire -->
					<s-button
						ref="submit"
						class="w-full mb-3 vs-con-loading__container"
						:disabled="disabled"
						@click="login"
					>
						{{ $t("Continuer") }}
					</s-button>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import { getDomain, getToken } from "sinao-corejs";
	import { Validator } from "vee-validate";
	import i18n from "../i18n";

	const dict = {
		custom: {
			email: {
				required: i18n.t("Veuillez entrer l'email"),
				email: i18n.t("L'email n'est pas valide")
			},
			password: {
				required: i18n.t("Veuillez entrer un mot de passe")
			},
			code: {
				length: i18n.t("Le code doit contenir 6 caractères")
			}
		}
	};

	Validator.localize("fr", dict);

	export default {
		data() {
			return {
				email: "",
				password: "",
				showLoginError: false,
				disabled: false,
				password_type: "password",
				showTwoFactorForm: false,
				lastCodeSent: null,
				code: Array(6).fill(""),
				codeError: null
			};
		},
		computed: {
			...mapState(["name"])
		},
		methods: {
			togglePassword() {
				this.password_type = this.password_type === "password" ? "text" : "password";
			},
			createAccount() {
				if (getDomain() === "sinao.app") {
					window.location = "https://www.sinao.fr/lp/essayez-gratuitement-sinao";
				} else {
					this.$router.push({ name: "Register" });
				}
			},
			async login() {
				const { email, password } = this;

				if (email && password) {
					const isFormValid = await this.$validator.validateAll();

					if (isFormValid) {
						this.disabled = true;

						this.$vs.loading({
							type: "default",
							color: "var(--white)",
							background: "var(--primary)",
							container: this.$refs.submit.$el,
							scale: 0.5
						});

						try {
							if (this.code.filter(char => char == "").length == 6) {
								const response = await this.$store.dispatch("login", {
									email,
									password
								});

								if (response?.requires_2fa) {
									this.showTwoFactorForm = true;
									this.codeError = null;
								} else if (!response?.requires_2fa) {
									this.getUser();
								}
							} else if (this.code.filter(char => char != "").length == 6) {
								const response = await this.$store.dispatch("login", {
									email,
									password,
									code: this.code.join("")
								});

								if (response) {
									this.getUser();
								}
							}
						} catch (error) {
							console.log(error);
							this.codeError = error.response?.data?.message || error.message;
							this.showLoginError = true;
							this.disabled = false;

							if (!this.showTwoFactorForm) {
								this.password = "";
							}
						}

						this.disabled = false;
						this.$vs.loading.close(this.$refs.submit.$el);
					}
				}
			},
			handleInput(index, event) {
				if (event && index < this.code.length - 1) {
					document.querySelector(`input[name="pin-${index + 2}"]`).focus();
				}

				if (this.code.filter(char => char != "").length == 6) {
					this.login();
				}
			},
			handleKeyNavigation(index, event) {
				if (event.key === "ArrowRight" && index < this.code.length - 1) {
					document.querySelector(`input[name="pin-${index + 2}"]`).focus();
				} else if (event.key === "ArrowLeft" && index > 0) {
					document.querySelector(`input[name="pin-${index}"]`).focus();
				} else if (event.key === "Backspace") {
					if (!this.code[index] && index > 0) {
						document.querySelector(`input[name="pin-${index}"]`).focus();
					} else {
						this.code[index] = "";
					}
				} else if (event.key === "Enter") {
					this.login();
				}
			},
			handlePaste(event) {
				const pastedData = event.clipboardData.getData("Text");

				if (pastedData && pastedData.length > 0) {
					this.code = Array.from(pastedData);
					document.querySelector(`input[name="pin-${pastedData.length}"]`).focus();
				}

				if (this.code.filter(char => char != "").length == 6) {
					this.login();
				}
			},
			async getUser() {
				await this.$store.dispatch("getUser").catch(error => {
					console.log(error);
				});

				this.$router.push({ name: "Apps" });
			},
			async resend_code() {
				if (this.lastCodeSent && new Date() - this.lastCodeSent < 30000) {
					this.$vs.notify({
						title: i18n.t("Merci de patienter 30 secondes avant de demander un nouveau code"),
						color: "danger",
						iconPack: "feather",
						icon: "icon-check",
						time: 5000
					});
					return;
				}

				try {
					await this.$store.dispatch("sendCodeLogin", {
						email: this.email,
						password: this.password
					});

					this.lastCodeSent = new Date();
					this.$vs.notify({
						title: i18n.t("Un nouveau code a été envoyé par mail"),
						color: "success",
						iconPack: "feather",
						icon: "icon-check",
						time: 5000
					});
				} catch (error) {
					console.log(error);
					this.codeError = error.response?.data?.message || error.message;
					this.$vs.notify({
						title: i18n.t("Une erreur est survenue lors de l'envoi du code. Veuillez contacter le support"),
						color: "danger",
						iconPack: "feather",
						icon: "icon-check",
						time: 5000
					});
				}
			}
		}
	};
</script>

<style lang="scss">
	#page-login {
		.social-login {
			.bg-facebook {
				background-color: #1551b1;
			}
			.bg-twitter {
				background-color: #00aaff;
			}
			.bg-google {
				background-color: #1551b1;
			}
			.bg-github {
				background-color: #333;
			}
		}
	}

	.card-login {
		max-width: 700px;
	}

	.login_eye_icon {
		margin-top: calc(21px / 2) !important;
	}

	.pin-input-group {
		display: flex;
		align-items: center;
	}

	.pin-input-group {
		.vs-con-input-label {
			width: 40px !important;
		}
	}

	.pin-input {
		border-radius: 6px;
		text-align: center;
		color: var(--text);
		font-size: 14px;
		width: 40px;
		height: 40px;
		margin-right: 4px;

		.vs-con-input {
			width: 40px;
			height: 40px;

			input {
				width: 40px;
				height: 40px;
				text-align: center;
			}
		}
	}
</style>
