<template>
	<div class="relative navbar" style="background-color: white !important">
		<div class="vx-navbar-wrapper">
			<vs-navbar class="vx-navbar navbar-custom" color="#ffffff" type="border">
				<a
					class="w-fit mr-5"
					tabindex="0"
					:title="$t('Retour à l accueil')"
					style="cursor: pointer"
					@click="redirectHomepage"
				>
					<img style="max-width: 110px; max-height: 24px; margin-top: 5px" :src="logo" />
				</a>

				<vs-spacer></vs-spacer>

				<div v-if="user" class="the-navbar__user-meta flex items-center sm:ml-5 ml-2">
					<div class="table">
						<div v-if="user.accounting_firm" class="hidden sm:table-cell" style="vertical-align: middle">
							<h5 class="uppercase mr-5 font-semibold">
								{{ accounting_firm_domain }}
							</h5>
						</div>
						<div v-if="user.accounting_firm" class="hidden sm:table-cell vertical-separator"></div>
						<div class="table-cell">
							<vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer flex Montserrat ml-5">
								<div class="text-right leading-tight sm:block max-content" style="color: #333">
									<p class="font-semibold">{{ user.firstname }} {{ user.lastname }}</p>
									<small>{{ user.email }}</small>
								</div>
								<div class="con-img ml-5">
									<img
										:src="getImageUrl(user)"
										width="40px"
										height="40px"
										class="rounded-full shadow-md cursor-pointer block"
										alt="votre image de profil"
										style="object-fit: contain"
									/>
								</div>
								<vs-dropdown-menu>
									<ul style="min-width: 9rem">
										<li
											class="flex py-2 px-4 cursor-pointer hover:bg-sinao hover:text-black"
											@click="logout"
										>
											<feather-icon icon="LogOutIcon" svg-classes="w-4 h-4"></feather-icon>
											<span class="ml-2">{{ $t("Déconnexion") }}</span>
										</li>
									</ul>
								</vs-dropdown-menu>
							</vs-dropdown>
						</div>
					</div>
				</div>

				<!--<div
					:class="{ faded: !getLoadingState }"
					class="vs-con-loading__container loading_container fade"
					ref="loaderContainer"
				></div>-->
			</vs-navbar>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapState } from "vuex";
	import { getToken } from "sinao-corejs";

	export default {
		name: "TheNavbar",
		computed: {
			...mapState(["user", "logo"]),
			...mapGetters(["getImageUrl"]),
			accounting_firm_domain() {
				return this.user?.accounting_firm?.domain ?? "";
			}
		},
		methods: {
			async logout() {
				await this.$store.dispatch("logout", getToken()).then(() => {
					this.$router.push({ name: "Login" });
				});
			},
			redirectHomepage() {
				if (!["pageLogin", "Apps"].includes(this.$route.name)) {
					this.$router.push({ name: "Apps" });
				}
			}
		}
	};
</script>

<style scoped>
	.max-content {
		width: max-content;
	}
	.vx-navbar-wrapper {
		background-color: #ffffff;
	}
	.navbar {
		position: sticky !important;
		top: 0;
		z-index: 9999;
	}
	.Montserrat {
		font-family: Montserrat;
	}
	.vs-con-loading__container.loading_container {
		height: 40px;
		overflow: visible;
		filter: blur(0.5px);
		width: 0;
		right: 20px;
		z-index: -1;
	}
	.fade {
		transition: opacity ease-in-out 100ms;
	}
	.faded {
		opacity: 0;
	}
	.vertical-separator {
		background-color: var(--grey);
		height: 100%;
		width: 1px;
	}
</style>
